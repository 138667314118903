import type { ReactNode } from 'react';
import { Flex, type FlexProps, Heading } from '@/ui';

type Props = FlexProps & {
  rightAccessory?: ReactNode;
};

export const PageHeading = ({ children, rightAccessory, ...props }: Props) => {
  return (
    // minHeight accounts for an accessory which may not be there,
    // but ensures all headings are at least the same height
    <Flex align="center" minH="42px" {...props}>
      <Heading flex="1" size="lg">
        {children}
      </Heading>
      {rightAccessory}
    </Flex>
  );
};
